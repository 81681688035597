
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle,
} from '@/core/helpers/breadcrumb';
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';
import { Actions } from '@/store/enums/StoreEnums';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { DrawerModeEnum } from '@/store/modules/MemberModule';
import { toastSuccess, toastError } from "@/core/services/Alert";
import images from '@/assets/ts/images.js'
import type { ElForm } from "element-plus";
import BasicModal from '@/components/modals/BasicModal.vue'
import { checkPermissionUpdate } from "@/core/helpers/helps";

export default defineComponent({
  name: 'members',
  components: {
    BasicModal
  },
  setup() {
    const { t } = useI18n();
    const phone = ref("");
    const bumns = ref([]);
    const isActiveUser = ref(false);
    const store = useStore();
    const drawerKey = ref(0)
    const formRef = ref<InstanceType<typeof ElForm>>();
    const userProfile = computed(() => store.getters.getUserProfile)
    const activeWorkspace = computed(() => store.getters.getActiveWorkspace)
    const selectedMember = ref()

    const getListMember = computed(() => {
      return store.getters.getListMembers;
    });
    const drawer = computed({
      get: () => store.getters.memberDrawerState,
      set: (v) => store.dispatch(Actions.UPDATE_MEMBER_DRAWER_STATE, v),
    });
    const drawerMode = computed({
      get: () => store.getters.memberDrawerMode,
      set: (v) => store.dispatch(Actions.UPDATE_MEMBER_DRAWER_MODE, v),
    });


    let form = ref({
      id: null,
      name: '',
      username: '',
      email: '',
      password: null,
      confirm_password: null,
      nip: '',
      phone: ''
    })


    const validatePass = (rule: any, value: any, callback: any) => {
      if (drawerMode.value === DrawerModeEnum.EDIT) {
        callback()
      } else if (!value || value === '') {
        callback(new Error(t('signUpPage.formPlaceholderPassword')))
      } else {
        if (form.value.password !== '') {
          if (!formRef.value) return
          formRef.value.validateField('password', () => null)
        }
        callback()
      }
    }

    const validatePass2 = (rule: any, value: any, callback: any) => {
      if (drawerMode.value === DrawerModeEnum.EDIT && (!form.value.password || form.value.password === '')) {
        callback()
      } else if (!value || value === '') {
        callback(new Error(t('signUpPage.formLabelConfirmPassword')))
      } else if (value !== form.value.confirm_password) { 
        callback(new Error(t('signUpPage.formLabelConfirmPasswordnotMatch')))
      } else {
        callback()
      }
    }

    const rules = reactive({
      name: [
        { required: true, message: t('roleAndPermissionPage.textRequired', { attr : t('signUpPage.formLabelName')}), trigger: 'blur', pattern: /^[^<>?!]*$/ },
      ],
      username: [
        { required: true, message: t('roleAndPermissionPage.textRequired', { attr : t('signUpPage.formLabelUsername')}), trigger: 'blur', pattern: /^[^<>?!]*$/ },
      ],
      nip: [
        {
          required: false,
          message: t('roleAndPermissionPage.textRequired', { attr : t('roleAndPermissionPage.textRequired', { attr : t('signUpPage.formLabelNIP')}) }),
          trigger: 'blur',
          pattern: /^[^<>?!]*$/
        },
      ],
      password:  [{ validator: validatePass, trigger: 'blur' }],
      confirm_password: [{ validator: validatePass2, trigger: 'blur' }],
      phone: [
        { required: true, message: t('roleAndPermissionPage.textRequired', { attr : t('signUpPage.formLabelPhone')}), trigger: 'blur' },
      ],
      email: [
        {
          required: true,
          type: 'email',
          message: t('roleAndPermissionPage.textRequired', { attr : t('signUpPage.formLabelEmail')}),
          trigger: 'blur',
          pattern: /^[^<>?!]*$/
        },
      ],
    })


    const headerCellClassName = () => {
      return 'bg-secondary';
    };
    const tableData = computed(() => store.getters.getUsers);

    const paginationData = computed(() => {
      return {
        current: store.getters.usersFilter.page > 1 ? store.getters.usersFilter.page + store.getters.usersFilter.limit - 1 : 1,
        to: store.getters.usersFilter.limit * store.getters.usersFilter.page,
        page: store.getters.usersFilter.page,
        ...store.getters.userPagination
      }
    })

    onMounted(() => {
      setTimeout(() => {
        store.dispatch(Actions.GET_USER_LIST, store.getters.usersFilter).then(() => {
          store.dispatch(Actions.GET_CHANNEL_LIST)
        })
      }, 2000);

      setCurrentPageTitle('membersPage.textMembers');
      setCurrentPageBreadcrumbs('membersPage.textMembers', [
        { title: ('common.setting'), path: '/setting' },
        {
          title: ('organization'),
          path: '/setting/organization/general-setting',
        },
      ]);
    });

    const deleteUserAction = reactive({
      dialog: false,
      data: null as any,
      loading: false,
      cancel() {
        deleteUserAction.dialog = false;
        deleteUserAction.loading = false;
        deleteUserAction.data = null;
      },
      submit() {
        deleteUserAction.loading = true;
        if (deleteUserAction.data) {          
          const id = deleteUserAction.data?._id;
          const role = deleteUserAction.data?.role;
          store
            .dispatch(Actions.DELETE_MEMBER, id)
            .then((response) => {
              toastSuccess(`${t('common.success')} ${t('common.delete')} ${t('membersPage.textMembers')}`)
              if(role) {
                detachUser(id, role?._id)
              }
              store.dispatch(Actions.GET_USER_LIST, store.getters.usersFilter);
            })
            .catch((error) => {
              toastError(`${t('common.failed')} ${t('common.delete')} ${t('membersPage.textMembers')}`)
              console.log({ error });
            })
            .finally(() => (deleteUserAction.loading = false));
          deleteUserAction.cancel();
        }
      },
    });

    const detachUser = (id_user, id_role) => {
      const payload = {
        id_user,
        id_role,
      };

      store
        .dispatch(Actions.UNASSIGN_USER_ROLE, payload)
        .catch((error) => {
          console.log("error", error);
        });
    };

    function deleteUser(data): void {
      deleteUserAction.dialog = !deleteUserAction.dialog;
      deleteUserAction.data = data
    }

    const submitting = ref(false);


    function openDrawer(member, mode = DrawerModeEnum.ADD) {
      drawerKey.value++

      store.dispatch(Actions.UPDATE_MEMBER_DRAWER_MODE, mode);
      store.dispatch(Actions.UPDATE_MEMBER_DRAWER_STATE, true);
      if (mode === DrawerModeEnum.EDIT) {
        form.value.id = member._id
        form.value.name = member.name
        form.value.username = member.username
        form.value.nip = member.nip
        form.value.email = member.email
        form.value.phone = member.phone
        bumnSelected.value = member.tenants.length > 0 ? member.tenants[0].workspaces[0].channels : []
        isActiveUser.value = member.is_active
      }
    }

    const submitButton = ref<HTMLElement | null>(null);
    const editMemberForm = ref();

    const closeDrawer = () => {
      form.value.id = null
      form.value.name = ' '
      form.value.username = ' '
      form.value.phone = ' '
      form.value.email = ' '
      form.value.password = null
      form.value.confirm_password = null
      phone.value = ''
      bumnSelected.value = []
      form.value.nip = ''
      isActiveUser.value = false      

      store.dispatch(Actions.UPDATE_MEMBER_DRAWER_STATE, false);
      store.dispatch(Actions.GET_USER_LIST, store.getters.usersFilter)

      drawerKey.value++
    }

    function handleEditSuccess() {
      closeDrawer();
      store.dispatch(Actions.GET_USER_LIST, store.getters.usersFilter);
    }

    const onSubmitRegister = (formEl: InstanceType<typeof ElForm> | undefined) => {
      if (!formEl) return

      formEl.validate((valid, fields) => {
        submitButton.value?.setAttribute("data-kt-indicator", "on");
        if (valid) {

          const payload = {
            id: form.value.id || undefined,
            name: form.value.name,
            username: form.value.username,
            email: form.value.email,
            password: form.value.password ? form.value.password : undefined,
            phone: form.value.phone,
            nip: form.value.nip,
            is_active: isActiveUser.value,
            tenants: [
                {
                    id: '631062f68c130d3fa0415ebf',
                    workspaces: [
                        {
                            id: '631063238c130d3fa0415ec2',
                            channels: bumnSelected.value.map((item) => item._id)
                        }
                    ]
                }
            ],
          };          

          if (drawerMode.value === DrawerModeEnum.ADD) {
            store
              .dispatch(Actions.CREATE_ACCOUNT, payload)
              .then((response) => {
                console.log("response register", response);
                toastSuccess(`${t('common.success')} ${t('common.add')} ${t('membersPage.textMembers')}`)
                closeDrawer();
              })
              .catch((errors) => {
                console.log("value error", errors);
                toastError(`${t('common.failed')} ${t('common.add')} ${t('membersPage.textMembers')}`)
              })
              .finally(() => {
                submitButton.value?.removeAttribute("data-kt-indicator");
              });
          } else if (drawerMode.value === DrawerModeEnum.EDIT) {
            store
              .dispatch(Actions.UPDATE_MEMBER_DETAIL, payload)
              .then((response) => {
                console.log("response register", response);
                toastSuccess(`${t('common.success')} ${t('common.update')} ${t('membersPage.textMembers')}`)
                closeDrawer();
              })
              .catch((errors) => {
                console.log("value error", errors);
                toastError(`${t('common.failed')} ${t('common.update')} ${t('membersPage.textMembers')}`)
              })
              .finally(() => {
                submitButton.value?.removeAttribute("data-kt-indicator");
                store.dispatch(Actions.GET_USER_LIST, store.getters.usersFilter)
              });
          }
        } else {
          console.log('error submit!', fields)
        }
        submitButton.value?.setAttribute("data-kt-indicator", "off");
      });
    }

    watch(
      () => form.value.phone,
      (after, before) => {
        form.value.phone = after?.replace(/\D/g, '');
      }, {
      deep: true
    }
    );

    watch(
      () => form.value.nip,
      (after, before) => {
        form.value.nip = after?.replace(/\D/g, '');
      }, {
      deep: true
    }
    );


    const prev = () => {
      store.dispatch(
        Actions.UPDATE_USERS_PAGE,
        store.getters.usersFilter.page - 1
      );
      store.dispatch(Actions.GET_USER_LIST, store.getters.usersFilter);
    };

    const next = () => {
      store.dispatch(
        Actions.UPDATE_USERS_PAGE,
        store.getters.usersFilter.page + 1
      );
      store.dispatch(Actions.GET_USER_LIST, store.getters.usersFilter);
    };

    const jumpTo = (lKey) => {
      store.dispatch(Actions.UPDATE_USERS_PAGE, lKey);
      store.dispatch(Actions.GET_USER_LIST, store.getters.usersFilter);
    };

    const changeLimit = () => {
      store.dispatch(Actions.UPDATE_USERS_PAGE, 1);
      store.dispatch(Actions.GET_USER_LIST, store.getters.usersFilter);
    };
    const showPassword = ref(false);
    const togglePassword = () => (showPassword.value = !showPassword.value);

    const showConfirmPassword = ref(false);
    const toggleConfirmPassword = () =>
      (showConfirmPassword.value = !showConfirmPassword.value);

    const bumnSelected:any = ref([])

    const getChannel = computed(() => {
      return store.getters.getListChannel;
    });

    const verifyMember = (id, type) => {
      const payload = {
          id, type
      }
      store.dispatch(Actions.VERIFY_MEMBER, payload).then((resps) => {
          toastSuccess(`${t('common.success')} ${type == 'approve' ? t('membersPage.textVerifyBtn') : t('membersPage.textDeclineBtn')} ${t('membersPage.textMembers')}`)
          closeDrawer()
      }).catch((error) => {
          toastError(`${t('common.failed')} ${type == 'approve' ? t('membersPage.textVerifyBtn') : t('membersPage.textDeclineBtn')} ${t('membersPage.textMembers')} : ${error}`)
      }).finally(() => {
          store.dispatch(Actions.GET_USER_LIST, store.getters.usersFilter)
      })
    }


    return {
      getListMember,
      bumns,
      showConfirmPassword,
      toggleConfirmPassword,
      showPassword,
      togglePassword,
      prev,
      next,
      jumpTo,
      changeLimit,
      submitButton,
      submitting,
      DrawerModeEnum,
      drawer,
      drawerMode,
      closeDrawer,
      openDrawer,
      Actions,
      store,
      tableData,
      deleteUser,
      deleteUserAction,
      headerCellClassName,
      editMemberForm,
      handleEditSuccess,
      bumnSelected,
      getChannel,
      onSubmitRegister,
      isActiveUser,
      phone,
      form,
      paginationData,
      images,
      drawerKey,
      rules,
      formRef,
      userProfile,
      selectedMember,
      verifyMember,
      checkPermissionUpdate
    };
  },
});
