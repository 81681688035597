
import { defineComponent, PropType } from "vue";

interface Img {
  src: string;
  alt: string | null;
}

export default defineComponent({
  name: "basic-modal",
  props: {
    id: {
      type: String,
      default: "basic_modal",
    },
    img: {
      type: Object as PropType<Img>,
      default() {
        return {
          src: "",
          alt: null,
        };
      },
    },
    position: {
      type: String,
      default: "",
    },
  },
  setup(props: any, context) {
    const onSubmit = () => {
      context.emit("submit");
    };

    const onCancel = () => {
      context.emit("cancel");
      document.getElementById("cancel_btn")?.click();
    };

    return {
      props,
      onSubmit,
      onCancel,
    };
  },
});
